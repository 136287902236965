<template>
  <div class="main">
    <nav-bar :nav-info='navInfo'></nav-bar>
    <!-- 二维码注册页面 -->
    <div class="vue_Qr"  @touchstart="toDownloadImg" @touchend="toChear" @touchmove="toChear">
      <div class="vue_Qr_img">
        <vue-qr ref="vueQrRef"  :text="QrData.url" :margin="20"   :logoSrc="QrData.icon " :logoScale="0.2" :size="240"></vue-qr>
      </div>
      <div class="vue_Qr_Name" @click="ShowPop" v-if="selectList.carGroup==''">{{$store.state.userInfo.realName}}</div>
      <div class="vue_Qr_Name" @click="ShowPop" v-else>{{selectList.carGroup}}</div>
      <div class="vue_Qr_ts">长按二维码下载分享</div>
    </div>
    <!-- 底部手动添加按钮 -->
    <div class="footer" @click="goRegister">
      <van-icon name="plus" size="0.37rem"></van-icon>
      手动添加
    </div>

    <!-- 车队弹窗 -->
    <van-popup v-model="show" round   position="bottom">
      <van-picker
        title="所属车队"
        show-toolbar
        :columns="carGroup"
        @confirm="selectCarGroup"
         @cancel="show = false"
      />
    </van-popup>
  </div>
</template>
<script>
import NavBar from '../../components/NavBar/NavBar.vue'
//vueQr动态生成二维码
import vueQr from 'vue-qr'
import { Toast } from 'vant'
export default {
  components: {
  vueQr,NavBar
  },
  data() {
    return {   
      //二维码参数
       QrData:{
         url:``,
        icon:require('../../assets/img/icon/qr_logo.png')
       },
      navInfo: {
        title:'添加运力',
        left:true,
        path:'/home'
      },
      timeOut:0,//计时器
      carGroup:[],
      show:false,
      selectList:{
        carGroup:'',
        carGroupid:''
      },
      deptId:'',
    }
  },
  created() {
    //deptId 全局都有等于userId
    this.deptId = this.$store.state.userInfo.deptId
    this.getCarGroup()
    this.getCode()
  },
  methods: {
    //显示选择车队弹框
    ShowPop() {
      if (this.carGroup.length>0) {
        this.show = true
      } else {
        Toast("您暂时还没有车队信息")
      }
      
    },
    //获取车队信息
    async getCarGroup() {
      console.log(this.$store.state.userInfo);
      let res =await this.$Api.queryCarGroup(this.$store.state.userInfo.deptId)
      console.log(res);
      let dateList = res.data
      dateList.map((item,index) => {
        this.carGroup.push(
          {
            id:item.id,
            text:item.captainName
          })
      })
      console.log(this.carGroup);
    },
    //选择所属车队
    selectCarGroup(value) {
      this.selectList.carGroup = value.text
      this.selectList.carGroupid = value.id
      this.getCode()
      this.show = false
    },
    //实时生成二维码
    getCode() {
      let  CodeUrldata = {
        deptId:this.deptId,
        carGroupid:this.selectList.carGroupid
      }
      let CodeUrldataStr = JSON.stringify(CodeUrldata)
      // 线上地址,拼接过去deptId和carGroupid车队id和userName是用户账号
      this.QrData.url=`http://www.so56.net/app/#/register?${this.deptId}&id${this.selectList.carGroupid}&=${this.$store.state.userInfo.userName}`
      // this.QrData.url=`http://192.168.26.46:8082/#/register?${this.deptId}&id${this.selectList.carGroupid}&=${this.$store.state.userInfo.userName}`//阳博的地址
    },
    // 长按下载二维码
    toDownloadImg() {
       this.timeOut = setTimeout(()=> {
         this.downloadImg();
       },500)
    },
    //清除定时器
    toChear() {
      clearTimeout(this.timeOut)
    },
    //下载二维码
    downloadImg() {
      var url = this.$refs.vueQrRef.$el.src
      // console.log(url);
      var createE = document.createElement('a')
      var event = new MouseEvent('click')
      //下载图片名字
      createE.download = '分享二维码.png'
      createE.href = url
      //执行下载
      createE.dispatchEvent(event)
    },
    //手动录入 当type为1时，则为手动录入，不是扫码录入
    goRegister() {
      this.$router.push({
        path:`/register`,
        query:{
          type:1
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
html,
body {
	height: 100%;
}
.main {
  background: #f5f5f5;
  height: 100vh;
  overflow: scroll;
}
.vue_Qr {
  width:300px;
  height:360px;
  position: relative;
  border-radius: 0.7rem;
  background: #ffffff;
  margin: 15vh 1rem  2rem 1rem;
  display: flex;
  justify-content: center;
  align-items:flex-start;
  img{
    margin-top: 1rem;
  }
  .vue_Qr_Name {
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    bottom: 59px;
  }
  .vue_Qr_ts {
    color: #999999;
    // width: 3rem;
    font-size: 12px;
    position: absolute;
    bottom: 10px;
    // left: 50%;
    // margin-left:-1.5rem;
  }
}
.footer {
  position: fixed;
  bottom: 3vh;
  left: 0.5rem;
  width: 9rem;
  height: 1rem;
  background: #fb6600;
  border-radius: 1rem;
  text-align: center;
  font-size: 0.37rem;
  color: #ffffff;
  line-height: 1rem;
}

</style>